<template>
    <div id="failed">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901104410526.png" alt="">
        <p style="font-size: 20px;margin-top: 10px;color: #666666">审核未通过！</p>
        <div style="color: #999999;min-width: 270px;margin-top: 11px">{{info.stateInfo}}</div>
    </div>
</template>

<script>
    export default {
        name: "failed",
        props: ['info']
    }
</script>

<style lang="scss">
    #failed {
        position: fixed;
        width: 100%;
        top: 40%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
    }
</style>