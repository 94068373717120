<template>
  <div :style="`width: ${clientWidth}px;height: ${clientHeight}px`" id="apply1">
    <div v-if="info.state == 10" class="shenqingshu">
      <div class="top_box">
        <p style="font-size: 16px;font-weight: bold">保证金差额退还申请书</p>
        <p style="color: #666666;margin-top: 10px;line-height: 22px">现申请退还保证金<span class="xiahuaxian">{{info.returnMoney}}</span>元，退款至本人蜜蛋平台美妆账户余额，退款后本人已缴保证金为<span class="xiahuaxian">{{info.currentMoney}}</span>元。保证金收据自即日起作废失效。</p>
        <div @click="agree = !agree" class="confirm_box">
          <div v-if="!agree" class="cancel_circle"></div>
          <img v-else style="width: 16px;height: 16px;margin-right: 10px;" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210826165402107.png" alt="">
          <div>阅读并同意《保证金差额退还申请书》</div>
        </div>
      </div>
      <div class="line" style="height: 10px;background-color: #F8F8F8;"></div>
      <div class="bottom_box">
        <p style="font-size: 15px;color: #666666">上传收据</p>
        <p style="color: #999999;margin-top: 2px">温馨提示：请根据[收据示例]上传您所持有的收据 </p>
        <div style="height: 1px;background-color: #f8f8f8;margin-top: 8px"></div>
        <div style="display: flex;justify-content: space-between;">
          <div v-for="(item, index) in fileSrc" style="position: relative;width: 160px;height: 100px;border-radius: 10px;overflow: hidden">
            <div v-if="item != ''" style="width: 100%;height: 100%">
              <van-image :src="item" fit="contain" style="width: 100%;height: 100%" alt="" />
              <van-uploader :after-read="uploadAgain" :name="index" style="position: absolute;top: 3px;right: 5px">
                <div style="background-color: #fff;padding: 3px 8px;border-radius: 5px;">重新上传</div>
              </van-uploader>
            </div>
            <van-uploader v-if="item == ''" :after-read="uploadAgain" :name="index">
              <div class="uploader_box">
                <img style="width: 24px;height: 23px" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210825155202770.png" alt="">
                <div style="color: #999999;margin-top: 6px">点击上传图片</div>
              </div>
            </van-uploader>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;padding-left: 20px;padding-right: 20px">
          <div style="width: 140px;height: 70px;position: relative">
            <img style="width: 100%" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901094652142.png" alt="">
            <!--                        <div class="forexample_box">示例</div>-->
          </div>
          <div style="width: 140px;height: 70px;position: relative">
            <img style="width: 100%" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901094721596.png" alt="">
            <!--                        <div class="forexample_box">示例</div>-->
          </div>
        </div>
      </div>
    </div>

    <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #FF98A0;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);" v-if="info.state == 10" @click="submit">
      提交申请
    </div>
    <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #FF98A0;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);" v-if="info.state == 30" @click="applyAgain">
      重新申请
    </div>
    <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #fff;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #000;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: 0 0 5px rgba(0,0,0,.1)" v-if="info.state == 20" @click="goMyCenter">
      返回个人中心
    </div>
    <applySuccess v-if="info.state == 40" :info="info" />
    <failed v-if="info.state == 30" :info="info" />
    <under-review v-if="info.state == 20" :info="info" />
  </div>
</template>

<script>
import applySuccess from "./applySuccess";
import failed from "./failed";
import underReview from "./underReview";
export default {
  name: "apply1",
  components: { applySuccess, failed, underReview },
  data() {
    return {
      clientHeight: '',
      clientWidth: '',
      fileSrc: ['', ''],
      brandId: '',
      info: '',
      agree: false
    }
  },
  created() {
    document.title = '持有收据申请'
    this.clientHeight = document.documentElement.clientHeight
    this.clientWidth = document.documentElement.clientWidth
    this.brandId = this.$route.params.brandId
    this.GetApplyChangeDetails()
  },
  methods: {
    GetApplyChangeDetails() {
      this.$toast.loading({ duration: 0 })
      this.post('/PersonCenter/TeamAgent/GetApplyChangeDetails', { brandId: this.brandId }, true, 2).then(data => {
        this.$toast.clear()
        if (data.code != 1) {
          return this.$dialog.confirm({
            title: '提示',
            message: data.msg
          })
            .then(e => {
              this.$router.back()
            })
            .catch(e => {
              this.$router.back()
            })
        }
        this.info = data.response
      })
    },
    goMyCenter() {
      window.location.href = this.baseHost + '/7999/Arongleweb/html/mycenter/manage.html'
    },
    applyAgain() {
      this.$toast.loading({ duration: 0 })
      this.post('/PersonCenter/TeamAgent/UpdateApplyChangeAgain', { id: this.info.id }, true, 2).then(data => {
        this.$toast.clear()
        if (data.code == 1) {
          this.$router.push(`/RefundDifference/${this.brandId}`)
        } else {
          this.$dialog.confirm({
            title: '提示',
            message: data.msg
          })
        }
      })
    },
    submit() {
      if (!this.agree) return this.$toast('请您勾选《保证金差额退还申请书》！')
      if (this.fileSrc.filter(item => item === '').length == 2) return this.$toast('请上传至少一张收据')
      this.$dialog.confirm({
        title: '提示',
        message: '确定提交申请吗？'
      }).then(() => {
        this.$toast.loading({ duration: 0 })
        const img = this.fileSrc.join()
        this.post('/PersonCenter/TeamAgent/UpdateApplyChange', {
          id: this.info.id,
          imgurl: img,
          isReceipt: 1
        }, true, 2).then(data => {
          this.$toast.clear()
          if (data.code == 1) {
            this.info.state = 20
          } else {
            this.$dialog.confirm({
              title: '提示',
              message: data.msg
            })
          }
        })
      })
        .catch(() => { })
    },
    uploadImg(e) {
      this.$toast.loading({ duration: 0 })
      return new Promise((resolve, reject) => {
        this.post('/CommAPI/Comm/UploadPictureBase64', {
          imgbase64str: e.split(',')[1],
          prefix: 'verified'
        }, true, 2).then(data => {
          resolve(data.response)
          this.$toast.clear()
        })
          .catch(data => {
            this.$toast.clear()
            this.$toast(data.msg)
          })
      })
    },
    uploadAgain(e, name) {
      const index = name.name
      this.uploadImg(e.content).then(data => {
        this.fileSrc.splice(index, 1, data)
      })
    }
  }
}
</script>

<style lang="scss">
#apply1 {
  text-align: left;
  background-color: #f8f8f8;
  .xiahuaxian {
    display: inline-block;
    min-width: 30px;
    text-align: center;
    border-bottom: 1px solid #333;
  }
  .shenqingshu {
    background-color: #fff;
    .top_box {
      padding: 15px 20px;
    }
    .bottom_box {
      padding: 15px 20px;
    }
    .uploader_box {
      background-color: #f8f8f8;
      border-radius: 10px;
      width: 155px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .forexample_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-40deg);
      border-radius: 5px;
      border: 1px solid #ff98a0;
      color: #ff98a0;
      font-size: 14px;
      width: 75px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .confirm_box {
    display: flex;
    margin-top: 35px;
    align-items: center;
    color: #999999;

    .cancel_circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid #aaaaaa;
      margin-right: 10px;
    }
  }
}
</style>