<template>
    <div id="underReview">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210825111424586.png" alt="">
        <p style="font-size: 20px;margin-top: 10px">审核中...</p>
        <div style="margin-top: 10px">退款金额：<span style="color: #FF98A0">{{info.returnMoney}}元</span></div>
        <div style="color: #999999;margin-top: 5px">因保证金特殊性需财务审核，审核完毕后系统将退至您的平台账户。</div>
    </div>
</template>

<script>
    export default {
        name: "underReview",
        props: ['info']
    }
</script>

<style lang="scss">
    #underReview {
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
    }
</style>